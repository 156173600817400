<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :no-label="noLabel"
      :label="label || 'Cidade'"
      :helper="helper"
      :helper-position="helperPosition || 'lb'"
      :error="error"
  >
    <erp-select
        @input="__set"
        :placeholder="placeholder || 'Qualquer'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :loading="loading"
        :disable="disable"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import UFs from '../../../../domain/remocao/helpers/UFs'
import http from '../../../../utils/services/http'

export default {
  name: 'CidadeSelect',
  mixins: [SelectMixin],
  props: {
    uf: String,
    ignoreForceModel: {type: Boolean, default: false}
  },
  data () {
    return {
      loading: false,
      result: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.uf && this.__bind(this.uf, true)
    })
  },
  watch: {
    uf (v) {
      this.__bind(v)
    },
    model () {
      !this.ignoreForceModel && this.$emit('cidade', this.model && this.model.nome ? this.model.nome : this.model)
    },
    value () {
      this.check()
    }
  },
  computed: {
    asyncdata () {
      let list = Array.isArray(this.result) ? this.result : []
      list = list.map((obj) => {
        return {
          value: {id: obj.id, nome: obj.nome},
          label: obj.nome
        }
      })
      !this.multiple && list.unshift({label: 'Qualquer', value: null})
      return list
    }
  },
  components: {},
  methods: {
    __bind (v, forceModel = false) {
      this.$nextTick(() => {
        if (!forceModel && !this.ignoreForceModel) {
          this.model = null
          this.__emit(null)
        }
        let find = UFs.find((item) => {
          return item.sigla === v
        })
        if (!find) {
          return
        }
        this.load(find.id)
      })
    },
    load (uf) {
      console.log('Find', this.model)
      this.loading = true
      http.get(`//servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`, {
        transformRequest (data, headers) {
          delete headers.common['Authorization']
          return data
        }
      })
        .then(response => {
          this.result = response.data
          if (this.model) {
            this.check()
          }
          this.loading = false
        })
        .catch(({response}) => {
          this.loading = false
        })
    },
    check () {
      this.$nextTick(() => {
        setTimeout(() => {
          let find = this.asyncdata.find((item) => {
            if (this.model && this.model.id){
              return item.value && Number(item.value.id) === Number(this.model.id)
            }
            return item.value && Number(item.value.id) === Number(this.model)
          })
          if (find) {
            this.model = find.value
            this.__emit(this.model)
          }
        }, 0)
      })
    }
  }
}
</script>
