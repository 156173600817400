import {mockEndereco} from './endereco'
import {mockTelefone} from './telefone'
import {mockEmail} from './email'
import {mockContato} from './contato'
import {mockDocument} from './document'

export const mockPessoa = {
    id: null,
    name: null,
    type: 1,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
}
