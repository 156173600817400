<template>
  <div>
    <e-row>
      <e-col>
        <e-row v-for="(telefone, index) in entidade.phoneNumbers" :key="telefone.id">
          <e-col>
            <e-row v-if="index === 0" mr>
              <e-col style="max-width: 280px">
                <erp-s-field
                    view="lr"
                    label=""
                    label-width="90px"
                >
                  <div class="font-11 font-bold m-b">Telefones</div>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-if="index > 2" class="m-b-sm" mr>
              <e-col style="max-width: 280px">
                <tipo-telefone-select field-view="lr" label-width="90px" v-model="telefone.purpose" />
              </e-col>
              <e-col class="flex items-center">
                <a class="text-negative" @click="removeTelefone(telefone)">remover este telefone</a>
              </e-col>
            </e-row>
            <e-row class="m-t-n" mr>
              <e-col style="max-width: 280px">
                <erp-s-field
                    view="lr"
                    :label="getTelefoneLabel(index)"
                    label-width="90px"
                >
                  <erp-input v-if="index > 2" size="2" v-model="telefone.phoneNumber" v-mask="'(##) # ####-####'" />
                  <div v-else>{{telefone.phoneNumber|formataTelefone}}</div>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
        <e-btn @click="adicionarTelefone" style="margin-left: 102px" label="Adicionar novo telefone" />
        <!-- -->
        <e-col>
          <e-row class="m-t-lg" mr>
            <e-col style="max-width: 280px">
              <erp-s-field
                  view="lr"
                  label=""
                  label-width="90px"
              >
                <div class="font-11 font-bold">Outros contatos</div>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row v-for="(contato, index) in entidade.contacts" :key="contato.id">
            <e-col>
              <e-row class="m-b-sm" mr>
                <e-col style="max-width: 280px">
                  <tipo-contato-select field-view="lr" size="2" label="Tipo:" label-width="90px"
                                       v-model="contato.purpose" />
                </e-col>
                <e-col class="flex items-center">
                  <a class="text-negative" @click="removeContato(contato)">remover este contato</a>
                </e-col>
              </e-row>
              <e-row class="m-t-n" mr>
                <e-col style="max-width: 340px">
                  <erp-s-field
                      view="lr"
                      label="Contato"
                      label-width="90px"
                  >
                    <erp-input size="2" v-model="contato.value" />
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-btn @click="adicionarContato" style="margin-left: 102px" label="Adicionar novo contato" />
        </e-col>
      </e-col>
      <e-col>
        <e-row mr>
          <e-col style="max-width: 280px">
            <erp-s-field
                view="lr"
                label=""
                label-width="90px"
            >
              <div class="font-11 font-bold m-b">E-mails</div>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t-n" mr>
          <e-col style="max-width: 340px">
            <erp-s-field
                view="lr"
                label="Principal:"
                label-width="90px"
            >
              <erp-input size="2" v-model="entidade.emails[0].email" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row v-for="(email, index) in entidade.emails" :key="email.id">
          <e-col v-if="index > 0">
            <e-row class="m-b-sm" mr>
              <e-col style="max-width: 280px">
                <tipo-email-select field-view="lr" size="2" label=" " label-width="90px" v-model="email.purpose" />
              </e-col>
              <e-col class="flex items-center">
                <a class="text-negative" @click="removeEmail(email)">remover este email</a>
              </e-col>
            </e-row>
            <e-row class="m-t-n" mr>
              <e-col style="max-width: 340px">
                <erp-s-field
                    view="lr"
                    label=""
                    label-width="90px"
                >
                  <erp-input size="2" v-model="email.email" />
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
        <e-btn @click="adicionarEmail" style="margin-left: 102px" label="Adicionar novo e-mail" />
      </e-col>
    </e-row>
  </div>
</template>

<script>
import {
  ErpSField,
  ErpInput,
} from 'uloc-vue-plugin-erp'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoTelefoneSelect from '../../../pessoa/components/include/TipoTelefoneSelect'
import {mockTelefone} from '../../../pessoa/mock/telefone'
import {mockEmail} from '../../../pessoa/mock/email'
import {mockContato} from '../../../pessoa/mock/contato'
import TipoEmailSelect from '../../../pessoa/components/include/TipoEmailSelect'
import TipoContatoSelect from '../../../pessoa/components/include/TipoContatoSelect'
import PersonMixin from '../../../pessoa/components/include/PersonMixin'
export default {
  name: 'Contato',
  mixins: [PersonMixin],
  components: {TipoContatoSelect, TipoEmailSelect, TipoTelefoneSelect, ECol, ERow, ErpSField, ErpInput},
  props: ['entidade'],
  methods: {
    getTelefoneLabel (index) {
      if (index === 0) {
        return 'Celular:'
      } else if (index === 1) {
        return 'Residencial:'
      }
      if (index === 2) {
        return 'Comercial:'
      }
      return ''
    },
    adicionarTelefone () {
      this.entidade.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
    },
    removeTelefone (telefone) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um telefone',
        message: 'Você tem certeza que deseja remover este telefone do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (telefone.id) {
          this.removeItem('telefones', this.entidade.id, telefone.id)
        }
        this.entidade.phoneNumbers.splice(this.entidade.phoneNumbers.indexOf(telefone), 1)
      }).catch(() => {
      })
    },
    adicionarEmail () {
      this.entidade.emails.push(JSON.parse(JSON.stringify(mockEmail)))
    },
    removeEmail (email) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um e-mail',
        message: 'Você tem certeza que deseja remover este e-mail do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (email.id) {
          this.removeItem('emails', this.entidade.id, email.id)
        }
        this.entidade.emails.splice(this.entidade.emails.indexOf(email), 1)
      }).catch(() => {
      })
    },
    adicionarContato () {
      this.entidade.contacts.push(JSON.parse(JSON.stringify(mockContato)))
    },
    removeContato (contato) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um contato',
        message: 'Você tem certeza que deseja remover este contato do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (contato.id) {
          this.removeItem('contatos', this.entidade.id, contato.id)
        }
        this.entidade.contacts.splice(this.entidade.contacts.indexOf(contato), 1)
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>
