import {date} from "uloc-vue"
import {mockEndereco} from "@/components/pessoa/mock/endereco"
import {mockTelefone} from "@/components/pessoa/mock/telefone"
import {mockEmail} from "@/components/pessoa/mock/email"
import {mockDocument} from "@/components/pessoa/mock/document"

export const adjustPurposes = function (objs) {
  objs.map(obj => {
    obj.map(item => {
      if (item.purpose && item.purpose.id) {
        item.purpose = item.purpose.id
      }
    })
  })
}

export const adjustPersonData = function (data) {
  if (data.pessoa && data.pessoa.birthDate && typeof data.pessoa.birthDate.date !== "undefined") {
    data.pessoa.birthDate = date.formatDate(data.pessoa.birthDate.date, 'DD/MM/YYYY')
  }
  // let mock = JSON.parse(JSON.stringify(mockArrematante))
  // console.log(mock)
  // this.arrematante = Object.assign(mock, data)
  // console.log(this.arrematante)
  if (!data.pessoa.addresses || data.pessoa.addresses.length === 0) {
    data.pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
  }

  if (!data.pessoa.phoneNumbers || data.pessoa.phoneNumbers.length < 3) {
    if (!Array.isArray(data.pessoa.phoneNumbers)) {
      data.pessoa.phoneNumbers = []
    }
    const len = 3 - Number(data.pessoa.phoneNumbers.length)
    for (let i = 0; i < len; i++) {
      data.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
    }
  }

  if (!data.pessoa.emails || data.pessoa.emails.length === 0) {
    data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
  }

  if (!data.pessoa.documents || data.pessoa.documents.length === 0) {
    data.pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
  }

  adjustPurposes([
    data.pessoa.addresses,
    data.pessoa.phoneNumbers,
    data.pessoa.contacts,
    data.pessoa.emails
  ])

  return data
}
