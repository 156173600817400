export let mockEndereco = {
  id: null,
  purpose: null,
  zip: null,
  address: null,
  number: null,
  district: null,
  districtId: null,
  complement: null,
  state: null,
  stateId: null,
  city: null,
  cityId: null,
  country: 'Brasil',
  countryId: 1,
  active: true
}
