<template>
  <div>
    <erp-s-field
        view="lr"
        label=""
        label-width="90px"
        class="m-b"
    >
      <erp-checkbox class="flex items-center" v-model="semEndereco">
        <label class="no-select font-bold">
          <span class="m-l-xs">Desabilitar endereço</span>
        </label>
      </erp-checkbox>
    </erp-s-field>
    <div v-if="!semEndereco">
      <e-row v-for="(endereco, index) in entidade.addresses" :key="endereco.id">
        <e-col>
          <e-row v-if="index <= 1" mr>
            <e-col style="max-width: 280px">
              <erp-s-field
                  view="lr"
                  label=""
                  label-width="90px"
              >
                <div class="font-11 font-bold m-b-xs" v-if="index === 0">Endereço principal</div>
                <div class="font-11 font-bold m-b-xs" v-else>Outros endereços</div>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col style="max-width: 280px">
              <tipo-endereco-select field-view="lr" label-width="90px" v-model="endereco.purpose" />
            </e-col>
            <e-col class="flex items-center" v-if="index >= 1">
              <a class="text-negative" @click="removeEndereco(endereco)">remover este endereço</a>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col style="max-width: 280px">
              <erp-s-field
                  view="lr"
                  label="CEP:"
                  label-width="90px"
              >
                <erp-input v-model="endereco.zip" v-mask="'#####-###'" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Endereço:"
              >
                <erp-input v-model="endereco.address" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 180px">
              <erp-s-field
                  view="lr"
                  label="Número:"
              >
                <erp-input v-model="endereco.number" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row mr>
            <e-col style="max-width: 280px">
              <erp-s-field
                  view="lr"
                  label="Bairro:"
                  label-width="90px"
              >
                <erp-input v-model="endereco.district" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Complemento:"
              >
                <erp-input v-model="endereco.complement" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 80px">
              <uf-select field-view="lr" label="UF:" v-model="endereco.state" />
            </e-col>
            <e-col style="max-width: 180px">
              <cidade-select field-view="lr" :uf="endereco.state" label="Cidade:" v-model="endereco.cityId"
                             @cidade="(v) => endereco.city = v" />
            </e-col>
          </e-row>
          <hr class="m-t-xs m-b-xs" v-if="index >= 1 && index < entidade.addresses.length - 1" />
        </e-col>
      </e-row>

      <e-btn style="margin-left: 102px" label="Adicionar novo endereço" @click="adicionarEndereco" />
    </div>
  </div>
</template>

<script>
import {mockEndereco} from '../../../pessoa/mock/endereco'
import CidadeSelect from '../../../remocao/components/include/CidadeSelect'
import UfSelect from '../../../remocao/components/include/UfSelect'
import PersonMixin from '../../../pessoa/components/include/PersonMixin'
import ECol from '../../../layout/components/Col'
import ERow from '../../../layout/components/Row'
import {
  ErpSField,
  ErpInput,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import TipoEnderecoSelect from '../../../pessoa/components/include/TipoEnderecoSelect'

export default {
  name: 'Endereco',
  mixins: [PersonMixin],
  components: {TipoEnderecoSelect, ERow, ECol, UfSelect, CidadeSelect, ErpSField, ErpInput, ErpCheckbox},
  props: {
    entidade: {required: true},
    autoNull: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      semEndereco: this.autoNull
    }
  },
  watch: {
    semEndereco (v) {
      this.$emit('semEndereco', v)
    }
  },
  methods: {
    adicionarEndereco () {
      this.entidade.addresses.push(JSON.parse(JSON.stringify(mockEndereco)))
    },
    removeEndereco (endereco) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um endereço',
        message: 'Você tem certeza que deseja remover este endereço do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (endereco.id) {
          this.removeItem('enderecos', this.entidade.id, endereco.id)
        }
        this.entidade.addresses.splice(this.entidade.addresses.indexOf(endereco), 1)
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>
