export const TIPO_PESSOA_FISICA = 1;
export const TIPO_PESSOA_JURIDICA = 2;

export const Tipo = {
  [TIPO_PESSOA_FISICA]: {title: 'Pessoa Física', class: 'pessoa-tipo-' + TIPO_PESSOA_FISICA},
  [TIPO_PESSOA_JURIDICA]: {title: 'Pessoa Jurídica', class: 'pessoa-tipo-' + TIPO_PESSOA_JURIDICA}
}

export const ESTADO_CIVIL_SOLTEIRO = 1;
export const ESTADO_CIVIL_CASADO = 2;
export const ESTADO_CIVIL_VIUVO = 3;
export const ESTADO_CIVIL_SEPARADO = 4;
export const ESTADO_CIVIL_DIVORCIADO = 5;

export const EstadoCivil = [
  {label: 'Solteiro(a)', value: ESTADO_CIVIL_SOLTEIRO},
  {label: 'Casado(a)', value: ESTADO_CIVIL_CASADO},
  {label: 'Viúvo(a)', value: ESTADO_CIVIL_VIUVO},
  {label: 'Separado(a)', value: ESTADO_CIVIL_SEPARADO},
  {label: 'Divorciado(a)', value: ESTADO_CIVIL_DIVORCIADO}
]
