import http from '../../../utils/services/http'

export const tiposEndereco = () => {
  // let url = '/api/pessoa/basic/tiposEndereco'
  let url = '/api/tiposEndereco'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const tiposTelefone = () => {
  // let url = '/api/pessoa/basic/tiposEndereco'
  let url = '/api/tiposTelefone'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const tiposEmail = () => {
  // let url = '/api/pessoa/basic/tiposEndereco'
  let url = '/api/tiposEmail'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const tiposContato = () => {
  // let url = '/api/pessoa/basic/tiposEndereco'
  let url = '/api/tiposContato'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const removePersonObject = (type, person, object) => {
  let url = `/api/pessoa/${person}/${type}/${object}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listPessoasSimple = () => {
  // let url = '/api/patio?page=' + page + '&limit=' + limit + filtros
  let url = `/api/pessoas`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export default {
  tiposEndereco,
  tiposTelefone,
  tiposEmail,
  tiposContato,
  listPessoasSimple
}
